import { FunctionComponent, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { CommonHelper, MsalLoginHelper } from '../../helpers';
import { LoginService, LoginNtlmService } from '../../services';
import store, { setCurrentCredentials, setToken } from '../../store';
import debug from '../../helpers/debug';
import envar from '../../environment-variables';
import { AuthorityToken } from '../../types';

const OAuth2Callback: FunctionComponent = ({ }) => {
  const history = useHistory();
  const { provider, profile } = useParams<{ provider: string, profile: string }>();


  const callback = async (search: string | undefined) => {

    if (search) {
      var urlParams = new URLSearchParams(search);
      let redirect_uri = new URL(window.location.pathname, window.location.origin);

      switch (provider) {
        case "ntlm":
        case "cert":
        case "basic":
          login(provider, urlParams)
            .then(returnPath => {
              console.log("return path", returnPath);
              if (returnPath) {
                history.replace(returnPath);
              }
              else {
                history.replace(envar.publicUrl ?? "/"); // Mover a root.
              }
            })
            .catch(err => {
              console.error("useEffect", "fetch error", CommonHelper.summaryAxiosError(err));
              history.replace("/error");
            });
          break;

        case "msal":
        case "msal2":
          let clientInfo = urlParams.get("client_info");

          if (clientInfo) {
            let settings = await LoginService.getSettingsMsal(profile);
            let state = urlParams.get("state");

            MsalLoginHelper.grantAccessCode(
              settings.clientId,
              settings.authority,
              "User.Read profile openid",
              redirect_uri,
              state
            );
          }
          else {
            loginMsal(profile, urlParams, redirect_uri)
              .then(returnPath => {
                console.log("return path", returnPath);
                if (returnPath) {
                  history.replace(returnPath);
                }
                else {
                  history.replace(envar.publicUrl ?? "/"); // Mover a root.
                }
              })
              .catch(err => {
                console.error("useEffect", "fetch error", CommonHelper.summaryAxiosError(err));
                history.replace("/error");
              });
          }
          break;

        default:
          history.replace("/error?code=unknown_provider");
          break;
      }
    }
    else {
      console.error(`Invalid format path: ${history.location}`);
      history.replace("/error");
    }
  }


  const login = async (providerName: string, urlParams: URLSearchParams): Promise<string | null> => {
    let code = urlParams.get("code");
    let returnUrl = urlParams.get("state");

    if (code) {
      return await
        LoginService.createToken(providerName, code)
          .then(async result => {
            await performLogin(result);
            return returnUrl;
          });
    }
    else {
      debug.error("error", "Invalid callback format.", history.location);
      throw "Invalid callaback format";
    }
  }

  const loginMsal = async (profile: string, urlParams: URLSearchParams, redirect_uri: string | URL): Promise<string | null> => {
    return new Promise<string | null>(async (resolve, reject) => {
      let code = urlParams.get("code");
      let session = urlParams.get("session_state");
      let state = urlParams.get("state");
      let info = urlParams.get("client_info");

      if (code) {
        console.log("state", { profile, session, state, info });
        return await
          LoginService.createTokenMsal(profile, code, redirect_uri, state)
            .then(async result => {
              let returnUrl = (state == null ? null : state.split('|')[1]);

              await performLogin(result);
              resolve(returnUrl);
            })
            .catch(reject)
      }
      else {
        debug.error("error", "Invalid callback format.", history.location);
        throw "Invalid callaback format";
      }
    });
  }

  const performLogin = async (result: AuthorityToken) => {
    console.log(result);
    store.dispatch(setToken(result));

    /*
    return await
      LoginService.getCurrentCredetials(result.accessToken)
        .then(response => {
          store.dispatch(setCurrentCredentials(response));
        });
    */

    store.dispatch(setCurrentCredentials({
      nameType: result.nameType,
      nameId: result.nameId
    }));
  }

  useEffect(() => {
    let search = (history.location.search ||
      (history.location.hash ? `?${history.location.hash.substring(1)}` : "")
    );
    callback(search);
  }, [provider]);
  return (null);
}

export default OAuth2Callback;
export { OAuth2Callback };