import HttpClient from './http-client';
import { CommonHelper } from '../helpers';
import envar from '../environment-variables';

class CommonService extends HttpClient {
  public constructor() {
    super(CommonHelper.combineUrl(envar.apiUrl as string, 'common'));
  }

  public getVersion = async (): Promise<any> => {
    return await
      this.instance.get<any>('version')
        .then(response => {
          return response.data;
        })
        .catch(err => {
          console.error("getVersion", "fetch error:", err);
          throw err;
        });
  }
}

const singleton = new CommonService();

export { singleton as CommonService };
export default singleton;