import { Authority, CurrentCredentials, AuthorityToken } from "../../types";

export const SET_CURRENT_CREDENTIALS = 'SET_CURRENT_CREDENTIALS';
export const SET_TOKEN = 'SET_TOKEN';

interface SetCurrentCredentials {
  type: typeof SET_CURRENT_CREDENTIALS;
  payload: CurrentCredentials | null;
}

interface SetToken {
  type: typeof SET_TOKEN;
  payload: AuthorityToken | null;
}

export type AuthorityTypes = SetCurrentCredentials | SetToken;

export interface AuthorityState extends Authority { }