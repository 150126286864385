import HttpClient from './http-client';
import { CommonHelper } from '../helpers';
import { AuthorityToken, CurrentCredentials } from '../types';
import debug from '../helpers/debug';
import envar from '../environment-variables';

class LoginNtlmService extends HttpClient {
  public constructor() {
    super(envar.apiUrl as string);
  }

  public createToken = async (provider: string, code: string): Promise<AuthorityToken> => {
    return await
      this.instance.post<AuthorityToken>(`auth/create-token`, { provider, code },
        { headers: { 'Content-Type': 'application/json' } },
      )
        .then(response => {
          return response.data;
        })
        .catch(err => {
          console.log(CommonHelper.summaryAxiosError(err));
          debug.error("createToken", "fetch error:", CommonHelper.summaryAxiosError(err));
          return err;
        });
  }

  public getCurrentCredetials = async (accessToken: string | null): Promise<CurrentCredentials> => {
    let rdo =
      await this.instance.get<CurrentCredentials>('auth',
        { headers: { 'Authorization': `Bearer ${accessToken}` } }
      )
        .then(response => {
          return response.data;
        })
        .catch(err => {
          debug.error("getCurrentCredentials", "fetch error:", CommonHelper.summaryAxiosError(err));
          return err;
        });
    return rdo;
  }

  public getLoginUrlsAsync = async (): Promise<{ ntlmOAuth2Address: string, ntlmApiAddress: string }> => {
    return await
      this.instance.get<{ ntlmOAuth2Address: string, ntlmApiAddress: string }>('auth/login-urls')
        .then(response => {
          return response.data;
        })
        .catch(err => {
          debug.error("getLoginUrlsAsync", "fetch error:", CommonHelper.summaryAxiosError(err));
          return err;
        });
  }

  public getNtlmVersion = async (): Promise<{ version: string }> => {
    let rdo = await this.getLoginUrlsAsync();
    return await
      this.instance.get<{ version: string }>(CommonHelper.combineUrl(rdo.ntlmApiAddress, 'common/version'))
        .then(response => {
          return response.data;
        })
        .catch(err => {
          debug.error("getVersion", "fetch error:", CommonHelper.summaryAxiosError(err));
          return err;
        });
  }

}

const singleton = new LoginNtlmService();

export { singleton as LoginNtlmService };
export default singleton;