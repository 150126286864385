import { CommonService, LoginService } from '../services';
import store, { setToken, setCurrentCredentials } from '../store';
import { CurrentCredentials, AuthorityToken, Authority } from '../types';
import { PublicClientApplication } from '@azure/msal-browser';
import debug from './debug';

class MsalLoginHelper {

  public loginMsal = async (profile: string, location: string | null) => {
    let redirect_uri = `${window.location.origin}/oauth2-callback/msal/${profile}`;
    let msalParameters = await LoginService.getSettingsMsal(profile);
    let client = new PublicClientApplication({
      auth: {
        clientId: msalParameters.clientId,
        authority: msalParameters.authority,
        redirectUri: redirect_uri,
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      }
    });
    await client.handleRedirectPromise();

    let accounts = client.getAllAccounts();
    if (accounts.length === 0) {
      client.loginRedirect({
        scopes: ["User.Read", "openid", "profile"],
        redirectUri: redirect_uri,
        state: `${location}`
      })
        .catch(e => {
          console.error(e);
        });
    }
  }

  public grantAccessCode = (clientId: string, authority: string, scope: string, redirectUri: string | URL, state: string | null = null) => {
    let URI = `${authority}/oauth2/v2.0/authorize`
      .concat("?response_type=code")
      .concat("&response_mode=query")
      .concat(`&client_id=${clientId}`)
      .concat(`&scope=${encodeURI(scope)}`)
      .concat(`&redirect_uri=${encodeURIComponent(redirectUri.toString())}`
      );

    if (state) {
      URI = URI.concat(`&state=${encodeURIComponent(state)}`)
    }
    window.location.replace(URI);
  };

}

const singleton = new MsalLoginHelper();

export default singleton;
export { singleton as MsalLoginHelper };