declare global {
  interface Window {
    runConfig: {
      apiDns?: string;
      publicUrl?: string;
    }
  }
}

const envar = new class EnvironmentVariables {

  apiUrl: string | undefined = window.runConfig.apiDns ? `${window.location.protocol}//${window.runConfig?.apiDns}/api/` : process.env.REACT_APP_API_URL;
  publicUrl: string | undefined = window.runConfig.publicUrl ?? process.env.REACT_APP_PUBLIC_URL;
  
}();

export { envar };
export default envar;